body {
  font-family: sans-serif;
}

h1, h2, h3 {
  margin-top: 0;
  margin-bottom: 16px;
}

h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 8px;
}

*, *::before, *::after {
  box-sizing: border-box;
}
